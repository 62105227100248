<template>
  <modal
    ref="modal"
    class-name="bg-blue-500 overflow-hidden relative text-white w-full sm:max-w-500px"
    @mounted="emitComponentMountedEvent"
  >
    <template>
      <button class="modal-close text-white text-2xl z-10" @click="close">
        <ion-icon name="close-outline"></ion-icon>
      </button>
    </template>
    <div
      class="absolute h-full inset-0 w-full z-1"
      style="opacity: 0.2;"
    >
      <img
        :src="assets.icons.xl.static"
        class="h-full w-full"
      />
    </div>
    <div class="mb-20 relative z-10 text-center">
      <div
        class="bg-white border-2 border-white grid h-20 mb-10 mx-auto place-items-center rounded-full text-blue-500 text-5xl w-20"
      >
        <ion-icon name="hand-left" />
      </div>
      <div class="relative z-10">
        <h3 class="font-bold mb-10 text-2xl md:text-4xl">Awaiting Approval</h3>
        <p class="font-medium mb-5 opacity-75 text-base md:text-lg">
          Your account is being processed, you should get a response shortly.
        </p>
      </div>
    </div>
    <div class="relative text-center z-10">
      <button
        type="button"
        class="btn btn-white-outline btn-md"
        @click="close"
      >
        Got it
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: "AwaitingApproval",
  computed: {},
  methods: {
    close() {
      this.$refs.modal.close();
    },
    emitComponentMountedEvent() {
      this.$emit("mounted");
    },
    open() {
      this.$refs.modal.open();
    },
  },
};
</script>

<style lang="sass" scoped>
.modal-content
  display: none
  color: #fff !important
</style>
